.member-detailed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.detailed-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 90%;
}
.detailed-item-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 20%;
}
.detailed-item-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-color: #c6c2c3;
    border-style: solid;
    border-width: 1px;
    margin-left: 13px;
}
.detailed-item-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-color: #c2c2c6;
    border-style: solid;
    border-width: 1px;
    margin-left: 3px;
}
.editable-cell {
    font-size: 1em;
    border: 0;
    pointer-events: auto;
    margin-bottom: 2px;
}
.group-cells-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
}
.close-button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.close-button {
    position: inherit;
    height: 20px;
    top: 10px;
    right: -10px;
    border-color: #c2c2c6;
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}
.unauthorized-users-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.unauthorized-user-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.group-blocks-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    /*background-color: #85c0e4;*/
}
.main-member-info-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*flex: 1;*/
    margin: 5px;
    /*background-color: yellow;*/
}
.additional-member-info-block {
    display: flex;
    flex-direction: column;
    margin: 5px;
    /*flex: 3;*/
    /*background-color: blue;*/
}
.notes-member-block {
    display: flex;
    flex: 4;
    margin: 5px;
    /*height: 100%;*/
    width: 100%;
    /*background-color: green;*/
}

.members-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}
.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
}
.header-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}
.buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.filter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.filter-options-container {
    position: fixed;
    top: 40px;
    right: 20px;
    border: solid 1px #7c7e7f;
    border-radius: 5px;
    background-color: white;
    z-index: 1100;
}
.filter-options-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background-color: #f8f8f7;
}
.filter-options {
    margin: 2px;
}

.tools-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    height: 100%;
}
.tools-options-container {
    position: fixed;
    top: 40px;
    right: 20px;
    border: solid 1px #7c7e7f;
    border-radius: 5px;
    background-color: white;
    padding: 15px;
    z-index: 1100;
}
.tools-options-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f8f8f7;
}

